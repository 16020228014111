//import * as React from 'react';
// Material
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// Component
import Title from './components/Title';


export default function About() {
  return(
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
      <Title>About</Title>
      <Typography variant="body" sx={{ paddingBottom: 2}}>
        Data Lumen is a simple tool that allows you to explore and understand a data table. <br/>
        The application has been designed to reduce grunt work required in data analysis. 
      </Typography>
      <Typography component="h4" variant="h7">Release 2.0.1.7 - Alyx
      </Typography>
      <Typography variant="body">
        Changed Climate horizon of 20 years with selected year in the center. Added scenario sp126 <br/>
        Added new metric 5 day maximum - as average of annual maximum of 5 day precipitation<br/>
        Distributed GDP Climate calculation with reduced run completion time<br/>
        New light weight backend application server<br/>
      </Typography>
      <Typography component="h4" variant="h7">Release 1.2.1.5 - Alice
      </Typography>
      <Typography variant="body">
        Filter supports finding missing in any column or not missing in all columns <br/>
        Filter supports smaller and greater (not equal) condition<br/>
        Grouping supports click on column filtering in details view<br/>
        Distribution supports click on column filtering in details view<br/>
        Scatterplots support filters.<br/>
      </Typography>
      <Typography component="h4" variant="h7">Release 1.1.6.1 - Hualālai
      </Typography>
      <Typography variant="body">
        Scatterplots shows on click details for a point<br/>
        Support and track customer project id in API accounting<br/>
        New application style <br/>
        Credit card payment support<br/>
        Messages are created for import and delete events<br/>
        Join supported between private files and shared files<br/>
        Improved Climate module with multiple aggregations for both original CMIP and GDDP
      </Typography>
      <Typography component="h4" variant="h7">Release 1.0.6 - Haleakalā</Typography>
      <Typography variant="body">
        Support for SBI Digital Hub <br/>
        Pivot and Aggregations support now count distinct and median <br/>
        Pivot tables support binning for Numbers <br/>
        Filters support now dates, not equal comparision and multiple values<br/>
        Filters have show all values for categories with less than 200 values <br/>
        Pivot table can be visualized as bar or chart <br/>
        Pivot table row and column are sorted alphabetically by name <br/>
        Pivot table supports measures with low count of distinct values as rows/columns<br/>
        Simple pivot table for categories as rows and columns
      </Typography>
      <Typography component="h4" variant="h7">Release 0.10.9 - Kamakou</Typography>
      <Typography variant="body">
        Added renaming of dataset display name <br/>
        Added search for column names and improve covariance calculation performance <br/>
        Support NASA NEX GDDP climate data import for organizations<br/>
        User Interface screen lock after 10 minutes of inactivity<br/>
        Data storage improvements to increase performance on dataset load<br/>
        Measure Histograms added to Group Totals. Revamped binning to be more natural<br/>
        Summary and Column tab merged. Frontend request caching added. <br/>
        Support tab delimited files in addition to comma and semicolumn delimited csv files <br/>
        Browse provides downloading and saving filtered column view<br/>
        Support hidding columns in Columnview<br/>
        Support file sharing between users in an organization
      </Typography>
      <Typography component="h4" variant="h7">Release 0.9 - Kohala</Typography>
      <Typography variant="body">
        Climate Web Service API Beta included <br/>
        Historical climate scenario supported <br/>
        Filter applied to Totals and Time Series and missing value filter<br/>
        Support disabling and re-enabling filter<br/>
        Support multiple filter conditions in Browse with multiple tests<br/>
        Selection of Nine climate models and and two scenarios<br/>
        Selection of climate variable and decade. Error handling<br/>
        Import climate data very simple first version to test concepts<br/>
        Sorting of Datasets. Support dates with variable digit day or month<br/>
        Refresh button for datasets
      </Typography>
      <Typography component="h4" variant="h7">Release 0.8 - Mauna Loa</Typography>
      <Typography variant="body">
        Enabling HTTP/2 to support large requests in cloud run <br/>
        Download csv file with a search filter inside of the search pane <br/>
        Support descriptive text or key columns with more than 65,000 unique strings <br/>
        Support geographical join between point locations (lat/lon) and grid style data with center point
      </Typography>
      <Typography component="h4" variant="h7">Release 0.7 - Kaʻala</Typography>
      <Typography variant="body">
        Moved map to Browse tab to allow simply switching between table and map <br/>
        Disable tabs for which no useful data is available. <br/>
        Search in maps supported. <br/>
        Maps show sample for large datasets and loads more accurate data on zoom in. <br/>
        Show Map for data with latitude and longitude. <br/>
        User Interface improvemnets. Changed copyright to upgrata. Bug fixes. 
      </Typography>
      <Typography component="h4" variant="h7">Release 0.6.0.1 - Lēʻahi</Typography>
      <Typography variant="body">
        Support joining two datasets with one string or long key column. <br/>
        Displaying now only unique key columns for right join column.
      </Typography>
      <Typography component="h4" variant="h7">Release 0.5</Typography>
      <Typography variant="body">
        Separated Development from Production instances <br/>
        Clicking on the correlation matrix open the scatterplot. Clicking on columns opens the histogram. <br/>
        Sorting added to the table in the browse tab <br/>
        Column specific search for the browse tab <br/>
        Browse tab with search over all columns <br/>
        User Interface improvements - added distinct to measures and attributes<br/>
        Consistency and Performance improvements<br/>
        Chart responsive to resizing - more zoom charts (click on chart) <br/>
        Bug fix for uploads with less than 30 rows and corrected correlation for columns with missing values. <br/>
        Completed performace improvements using saved schema definition. <br/>
        Precalculate file analysis at upload. Added the number of rows to datasets <br/>
        Internal efficiency improvements, doubling speed of Timer Series and Scatterplots
      </Typography>
      <Typography component="h4" variant="h7">Release 0.4</Typography>
      <Typography variant="body">
        Support European date formats dd?MM?yy and dd?MM?yyyy (missing test case) <br/>
        Default date/time in time series if there is only one date available <br/>
        Supports deleting of uploaded files. <br/>
        Improve Charts. Attribute columns type added for identifiers and descritive columns<br/>
        Click on Aggregation chart / Scatterplot to expand chart. Click again to deselect.<br/>
        Support for the literals <em>null</em>, <em>na</em>, <em>n/a</em> and <em>#missing</em> for mising values.
        Null column support and fixes for localized numbers.  <br/>
        Scale y-Axis by order of magnitude. For US Locale numbers with thousand separators are supported.  <br/>
        Support new date formats yyyymm and yyyymmdd in additon to yyyy-mm-dd and mm/dd/yyyy.
        Increased storage efficiency. User interface clean up.  <br/>
        Correlation tab allows to select a measure and show sampled scatterplots
      </Typography>
      <Typography component="h4" variant="h7">Release 0.3</Typography>
      <Typography variant="body">
        Time Series tab shows all measures by selected date.<br/>
        Analysis allows to analyze by year - switches to year/month if 3 or less years
        Support for aggregation by date/time by year. <br/>
        Choice for aggregation function (sum or average).
        First limited locale support US,UK,DE,FR for CSV files - limited test data <br/> 
        Change registration flow with license, forget password message support. <br/>
        Usebility - show selected dataset in breadcrumbs
      </Typography>
      <Typography component="h4" variant="h7">Release 0.2</Typography>
      <Typography variant="body">
        New "Totals" tab showing the average of measures by category <br/> 
        Wait Indicator and other user interface improvements implemened.
        Top 7 for Regression matrix. Clean up of User Interface.
        User Registration. Improved error handling
        Correlation View, Improved Y-Axis scaling 
      </Typography>
      <Typography component="h4" variant="h6" sx={{pt:2}}>Open Source Packages</Typography>
      <Typography variant="body">
        Lincenses are available on the web sites: <br/>
        <Link target='_blank' color="inherit" href="https://tomcat.apache.org/" underline="none">Apache Tomcat</Link> <br/> 
        <Link target='_blank' color="inherit" href="https://reactjs.org/" underline="none">React</Link> <br/> 
        <Link target='_blank' color="inherit" href="https://mui.com/" underline="none">MUI</Link> <br/> 
        <Link target='_blank' color="inherit" href="https://recharts.org/en-US/" underline="none">Recharts</Link> <br/> 
      </Typography>
    </Paper>
  );
}